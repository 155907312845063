















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class LogsTable extends Vue {
  @Prop() items
  @Prop() fields
  @Prop({ type: Boolean, default: true }) striped
}
